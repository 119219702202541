<template>
  <div class="vip_buy_warp">
    <van-overlay :show="show">
      <div class="vip_buy_box">
        <div class="info">
          <p>{{ content }}</p>
          <p>{{ vipText }}</p>
        </div>
        <div class="btn_box">
          <div class="share" @click="$router.push('/sharePromote')">分享推广</div>
          <div class="openVip" @click="$router.push('/memberCentre?t=vip')">立即开通</div>
        </div>
        <div class="close" @click="$emit('close')">
          <svg-icon icon-class="close4" />
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { Overlay } from 'vant';

export default {
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    content: {
      type: String,
      default: '您还不是会员哦～',
    },
    vipText: {
      type: String,
      default: '开通VIP观看完整视频',
    },
  },
  components: {
    [Overlay.name]: Overlay,
  },
};
</script>
<style lang="scss" scoped>
.vip_buy_warp {
  /deep/ .van-overlay {
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .vip_buy_box {
    width: 318px;
    height: 234px;
    background: url('../../assets/png/vip_bg.png') no-repeat;
    background-size: cover;
    text-align: center;
    margin-bottom: 10%;
    position: relative;

    .vip_card {
      text-align: center;
      margin-top: -50px;

      > svg {
        font-size: 120px;
      }
    }

    .info {
      color: #425453;
      padding-top: 98px;
      text-align: center;
      line-height: 28px;
      font-size: 14px;
      p:nth-child(1) {
        color: #565656;
      }

      p:nth-child(2) {
        color: #000000;
      }
    }
    .btn_box {
      display: flex;
      justify-content: space-around;
      padding-top: 14px;
    }

    .openVip {
      width: 136px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      border-radius: 28px;
      font-size: 16px;
      color: #ff678f;
      border: 1px solid #ff678f;
    }

    .share {
      width: 136px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      border-radius: 28px;
      font-size: 16px;
      border: 1px solid #666666;
      color: #666666;
    }

    .close {
      position: absolute;
      font-size: 32px;
      bottom: -60px;
      left: 50%;
      margin-left: -16px;
    }
  }
}
</style>
