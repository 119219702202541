<template>
  <div class="vip_buy_pop_warp">
    <!-- <van-overlay :show="show">
      
    </van-overlay> -->
    <van-popup class="vip_buy_pop" :close-on-click-overlay="false" v-model="show">
      <div class="vip_buy_pop_box" v-if="advList.length">
        <VipPopSwipe :advList="advList" v-if="advList.length" :hasPagination="false" class="adv"/>
        <div class="close" @click="$emit('close')">
          <svg-icon icon-class="close4"/>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import {Overlay, Popup} from "vant";
import {mapGetters} from "vuex";
import VipPopSwipe from "@/components/VipPopSwipe";
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  components: {
    VipPopSwipe,
    [Popup.name]: Popup,
    // [Overlay.name]: Overlay,
  },
  computed: {
    ...mapGetters({
      imgApi: "imgApi",
    }),
  },
  data() {
    return {
      advList: [],
    }
  },
  created() {
    // this.advList = JSON.parse(this.$store.state.app.appConfig).pops || [];
    if (Object.prototype.toString.call(this.$store.state.app.appConfig) === "[object String]") {
      this.advList = JSON.parse(this.$store.state.app.appConfig)?.pops ?? [];
    } else {
      this.advList = this.$store.state.app.appConfig?.pops ?? [];
    }
  },
  mounted() {
  }
}
</script>
<style lang="scss" scoped>
.vip_buy_pop_warp {
  // -webkit-backface-visibility: hidden;
  // -moz-backface-visibility: hidden;
  // -webkit-transform: translate3d(0, 0, 0);
  // -moz-transform: translate3d(0, 0, 0);
  /deep/ .van-overlay {
    z-index: 9999 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /deep/ .van-popup {
    position: absolute;
  }
  .vip_buy_pop {
    background: #00000000;
    z-index: 9999!important;
  }

  .vip_buy_pop_box {
    width: 325px;
    // height: 395px;
    text-align: center;
    // margin-bottom: 10%;
    

    // .vip_card {
    //   text-align: center;
    //   margin-top: -50px;

    //   > svg {
    //     font-size: 120px;
    //   }
    // }

    // .info {
    //   color: #425453;
    //   padding-top: 50px;
    //   text-align: center;
    //   line-height: 32px;
    //   font-size: 16px;

    //   p:nth-child(2) {
    //     color: #000000;

    //   }
    // }

    // .openVip {
    //   width: 252px;
    //   height: 38px;
    //   text-align: center;
    //   line-height: 38px;
    //   background: #ff9f19;
    //   border-radius: 6px;
    //   margin: 20px auto;
    //   font-size: 16px;
    //   box-shadow: 4px 4px 12px rgba(#ff7527, 0.5);

    // }

    // .share {
    //   font-size: 16px;
    //   text-align: center;
    //   color: #425453;
    //   display: inline-block;
    //   padding-top: 2px;
    //   padding-bottom: 4px;
    //   border-bottom: 0.5px solid rgba(#425453, 0.6);
    // }

    .close {
      // position: absolute;
      font-size: 32px;
      margin-top: 23px;
      // bottom: -60px;
      // left: 50%;
      // margin-left: -16px;
    }
  }

}
</style>

