<template>
  <div class="gold_buy_warp">
    <van-overlay :show="show">
      <div class="gold_buy_box">
        <div class="buy_content">
          <!-- <p v-if="!isMovies" class="up flex-center-center">
            该内容由UP主【
            <span class="name">{{ videoInfo.publisher.name || '未知' }}</span>
            】上传，并设置价格为：
          </p> -->
          <div class="price_box">
            <svg-icon icon-class="tip_gold" />
            <span class="plice">{{ isVip ? videoInfo.coins : videoInfo.originCoins }}金币</span>
          </div>
          <p class="tip">购买解锁后才能观看完整视频哟！</p>
          <p class="amount">钱包余额：{{ balance }}</p>
          <div class="goldBuying" @click="goldBuying">
            {{ buyInfoText }}
          </div>
        </div>
        <div class="close_box" @click="$emit('close')">
          <div class="close">
            <svg-icon icon-class="y_close" />
          </div>
        </div>
      </div>
    </van-overlay>
    <van-overlay v-show="buyLoading">
      <div class="buy_loading">
        <Loading />
        <p>购买中...</p>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { Overlay, Toast } from 'vant';
import { buyVideo } from '@/api/video';
import Loading from '@/components/Loading';
import { mapGetters } from 'vuex';

export default {
  props: {
    videoInfo: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    isMovies: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '本视频需要购买解锁',
    },
    isVip: {
      type: Boolean,
      required: true,
      default: () => {
        return false;
      },
    },
    show: {
      type: Boolean,
      required: true,
      default: () => {
        return false;
      },
    },
  },
  computed: {
    ...mapGetters({
      walletInfo: 'walletInfo',
    }),
    balance() {
      return ((this.walletInfo?.amount || 0) + (this.walletInfo?.income || 0) + (this.walletInfo?.incomePot || 0)).toFixed(1);
    },
    buyInfoText() {
      let payAmount = this.isVip ? this.videoInfo?.coins : this.videoInfo?.originCoins;
      if (payAmount > this.balance) {
        return '余额不足,前往充值';
      }
      return '立即购买';
    },
  },
  components: {
    [Overlay.name]: Overlay,
    Loading,
  },
  data() {
    return {
      buyLoading: false,
    };
  },
  methods: {
    //购买视频
    async goldBuying() {
      let productID = this.videoInfo.id;
      if (!productID) {
        Toast('购买出错，请退出app，重新进入购买');
        return;
      }
      let payAmount = this.isVip ? this.videoInfo?.coins : this.videoInfo?.originCoins;
      if (payAmount > this.balance) {
        this.$router.push('/myWallet');
        return;
      }
      let req = {
        productType: 1,
        productID: productID,
      };
      try {
        this.buyLoading = true;
        let res = await this.$Api(buyVideo, req);
        this.buyLoading = false;
        if (res.code === 200) {
          Toast('购买成功');
          this.$emit('succcess');
          this.$store.dispatch('user/getWallet');
          return;
        }
        Toast(res.tip || '购买失败');
      } catch (e) {
        this.buyLoading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.mb20 {
  margin-bottom: 20px;
}
.gold_buy_warp {
  /deep/ .van-overlay {
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .buy_loading {
    color: #ee0a24;
    font-size: 14px;
  }

  .gold_buy_box {
    width: 318px;
    height: 265px;
    background: url('../../assets/png/gold_buy_bg.png') no-repeat;
    background-size: cover;
    margin-bottom: 20%;
    border-radius: 17px;
    position: relative;
    color: #171717;
    text-align: center;

    h2 {
      padding: 18px 0 10px 8px;
      border-bottom: 1px solid #e6e6e6;
      font-size: 16px;
    }

    .buy_content {
      .up {
        font-size: 11px;
        color: #a29992;
        padding-top: 12px;

        .name {
          max-width: 80px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
        }
      }

      .price_box {
        font-size: 22px;
        padding-top: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

        > svg {
          font-size: 27px;
          padding-right: 4px;
        }
      }
      .tip {
        font-size: 14px;
        padding-top: 13px;
        padding-bottom: 5px;
      }
      .amount {
        width: 228px;
        font-size: 12px;
        padding: 2px 0 16px 0;
        color: #17171760;
        text-align: center;
        margin: auto;
      }

      .goldBuying {
        width: 224px;
        height: 38px;
        margin: 0 auto;
        border-radius: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ff678f;
        color: #ff678f;
        font-size: 16px;
      }
    }

    .line {
      margin-top: 8px;
      width: 314px;
      height: 1px;
      background: #e6e6e6;
    }

    .info {
      width: 228px;
      font-size: 11px;
      color: #a29992;
      padding: 12px 0 15px 0;
      margin: 0 auto;
      text-align: left;
    }

    .close_box {
      position: absolute;
      bottom: -70px;
      width: 100%;
      display: flex;
      justify-content: center;

      .close {
        svg {
          font-size: 33px;
        }
      }
    }
  }
}
</style>
